const documentsFilters = [
  {
    type: 'date',
    id: 'date',
    label: 'Fecha',
    value: null,
    value2: null,
    category: 'a',
    operator: null,
    operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
    countries: ['PE', 'CL']
  },
  {
    type: 'select',
    isMultiple: true,
    id: 'type',
    value: null,
    label: 'Tipo de documento',
    choices: [],
    category: 'b',
    countries: ['PE', 'CL']
  },
  {
    type: 'numeric',
    id: 'serial',
    label: 'Serie',
    value: null,
    category: 'c',
    countries: ['PE']
  }
]
export {
  documentsFilters
}